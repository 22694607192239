import moment from 'moment'
import numeral from 'numeral'

export default {
  methods: {
    dateTimeFormatterLong (value) {
      if (value) {
        return moment(value).format('MMMM DD, YYYY - LT')
      }
      return null
    },

    dateFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD')
      }
      return null
    },

    dateTimeFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD HH:mm:ss')
      }
      return null
    },

    dateTimeLocalFormatter (value) {
      if (value) {
        return moment(value).format('YYYY-MM-DDTHH:mm')
      }
      return null
    },

    numberFormatter (value) {
      return numeral(value).format('0,0.00')
    },

    objectToUrl (objects) {
      return Object.keys(objects).map(
        key => (typeof objects[key] === 'undefined' || objects[key] === 'null' || objects[key] === null) ? `${key}=` : `${key}=${objects[key]}`
      ).join('&').toString()
    },

    properCase (str) {
      if (str) {
        return str.toLowerCase().split(' ').map(function (word) {
          return word.replace(word[0], word[0].toUpperCase())
        }).join(' ')
      }
      return ''
    }
  }
}
